const SITEMODEL = [
  {
    value: 1,
    label: '机库＋射频基站'
  },
  {
    value: 2,
    label: '射频基站'
  },
  {
    value: 3,
    label: '单兵中枢'
  },
  {
    value: 4,
    label: '遥控器'
  },
  {
    value: 5,
    label: '机载计算机'
  }
]


export {
  SITEMODEL
}