import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;



//获取无人机列表
function getDroneList(page, size, query = '') {
  return request({
    url: `${server}/dataCenter/qcp/uavs/list?page=${page}&size=${size}&UAVID=${query}`,
    method: 'GET'
  })
}

//新增无人机信息
function increaseDrone(data) {
  return request({
    url: `${server}/${base_url.core}/uavs/add`,
    method: 'POST',
    data
  })
}

//删除无人机信息
function delectDrone(droneId = '') {
  return request({
    url: `${server}/${base_url.core}/uavs/delete?UAVID=${droneId}`,
    method: 'DELETE'
  })
}

//更新无人机信息
function varyDroneInfomation(data) {
  return request({
    url: `${server}/${base_url.core}/uavs/update`,
    method: 'PUT',
    data,
  });
}

export {
  getDroneList,
  increaseDrone,
  delectDrone,
  varyDroneInfomation
}