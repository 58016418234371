import request from "@/utils/request";
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;



//获取边缘计算机信息
export function getOnboardComputers(page, size, ECID = '') {
  return request({
    url: `${server}/dataCenter/qcp/ecs/list?page=${page}&size=${size}&ECID=${ECID}`,
    method: 'GET',
  })
}

//添加边缘计算机
export function writeOnboardComputer(data) {
  return request({
    url: `${server}/${base_url.core}/ecs/add`,
    method: 'POST',
    data
  })
}

export function editOnboardComputer(data) {
  return request({
    url: `${server}/${base_url.core}/ecs/update`,
    method: 'PUT',
    data
  })
};

export function deleteOnboardComputer(ECID) {
  return request({
    url: `${server}/${base_url.core}/ecs/delete?ECID=${ECID}`,
    method: 'DELETE'
  })
}

