<template>
  <!-- 边缘计算机 -->
  <div class="onboard-computer-wrapper">
    <el-card class="obboard-computer-info">
      <el-form slot="header" size="small" :model="onboardComputer" :inline="true">
        <el-form-item label="机身号">
          <el-input v-model="onboardComputer.SN">
            <el-button slot="append" icon="el-icon-search" @click="onSubmit(droneInfomation.id)">查询</el-button>
          </el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button size="small" icon="el-icon-circle-plus-outline" type="primary" @click="handleClickAdd" v-permission="['add']">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="onboardComputers" :height="tHeight">
        <el-table-column type="index" align="center" label="序号" width="60"></el-table-column>
        <el-table-column align="center" label="机身号" prop="ECID" width="140"></el-table-column>
        <el-table-column align="center" label="型号" prop="model"></el-table-column>
        <el-table-column align="center" label="生产厂家" prop="brand"></el-table-column>
        <el-table-column align="center" label="物理(mac)地址" prop="mac" width="140"></el-table-column>
        <el-table-column align="center" label="IP地址" prop="ipAddr" width="180"></el-table-column>
        <el-table-column align="center" label="状态" prop="system"></el-table-column>
        <el-table-column align="center" label="描述" prop="context" v-if="false"></el-table-column>
        <el-table-column align="center" label="无人机编号" prop="UAVID" width="180"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createdTime" width="160"></el-table-column>
        <el-table-column align="center" label="创建人" prop="createUser"></el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime" width="160"></el-table-column>
        <el-table-column align="center" label="创建人" prop="updateUser"></el-table-column>
        <el-table-column align="center" label="状态时间" prop="lastOnlineTime" width="160"></el-table-column>
        <el-table-column align="center" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="handleEditOnboardComputed(scope.row)" v-permission="['edit']">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" style="color: #f40" @click="handleDeltetOnboardComputer(scope.row.ECID)" v-permission="['remove']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="pagination-bar">
        <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" @current-change="handleCurrentChange" :total="total"></el-pagination>
      </el-row>
      <!-- @close="handleClose('OnboarComputer')"  :before-close="handleClose('OnboarComputer')" -->
      <el-dialog :close-on-click-modal="false" title="边缘计算机信息" :visible.sync="showForm" @close="handleClose('OnboarComputer')" @open="handleOpen">
        <el-form ref="OnboarComputer" :rules="rules" :model="onboardComputerDetails" label-position="left" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="机身号" prop="ECID">
                <el-input v-model="onboardComputerDetails.ECID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="制造商" prop="brand">
                <el-input v-model="onboardComputerDetails.brand"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="IP地址" prop="ipAddr">
                <el-input v-model="onboardComputerDetails.ipAddr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物理(mac)地址" prop="mac">
                <el-input v-model="onboardComputerDetails.mac"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="型号" prop="model">
                <el-select v-model="onboardComputerDetails.model" style="width: 100%">
                  <el-option v-for="option in models" :value="option.value" :label="option.label" :key="option.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="state">
                <el-select v-model="onboardComputerDetails.state" style="width: 100%">
                  <el-option v-for="state in states" :value="state.value" :label="state.label" :key="state.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="操作系统" prop="system">
                <el-select v-model="onboardComputerDetails.system" style="width: 100%">
                  <el-option v-for="system in systems" :value="system.value" :label="system.label" :key="system.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="无人机" prop="UAVID">
                <el-select filterable v-model="onboardComputerDetails.UAVID" style="width: 100%">
                  <el-option v-for="uav in uavs" :value="uav" :label="uav" :key="uav"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属企业" v-if="hasChangeCp" prop="CPID">
                <el-select clearable v-model="onboardComputerDetails.CPID" style="width: 100%" @change="handleChangeCorporation">
                  <el-option v-for="(cp, index) in CPList" :label="cp.cpName" :value="cp.cpId" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属项目">
                <el-select clearable v-model="onboardComputerDetails.PID" style="width: 100%">
                  <el-option v-for="(project, index) in projectList" :label="project.pName" :value="project.pId" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注">
            <el-input type="textarea" maxlength="200" show-word-limit v-model="onboardComputerDetails.context"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 120px" type="primary" @click="submit('OnboarComputer')">确定</el-button>
            <el-button style="width: 120px" type="danger" @click="resetForm('OnboarComputer')">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getOnboardComputers, writeOnboardComputer, editOnboardComputer, deleteOnboardComputer } from '@/api/ecs.js';
import { getDroneList } from '@/api/drone.js';
import { getProjectBycpIds } from '@/api/accraditation.js';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import { SITEMODEL } from '@/utils/form.config.js';
import statusInclude from '@/utils/statusCode';
import sleep from '@/utils/sleep';
export default {
  name: 'OnboardComputer',
  data() {
    var validateMAC = (rule, value, callback) => {
      const reg = /^((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}$/i;
      if (value === '') {
        callback(new Error('请输入mac物理地址'));
      } else if (!reg.test(value)) {
        callback(new Error('物理地址格式错误'));
      } else {
        callback();
      }
    };
    var validateIPAddr = (rule, value, callback) => {
      const reg = /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
      if (value === '') {
        callback(new Error('请输入IP地址'));
      } else if (!reg.test(value)) {
        callback(new Error('IP地址格式错误,例：127.0.0.1'));
      } else {
        callback();
      }
    };
    return {
      tHeight: 0,
      page: 1,
      size: 13,
      total: 0,
      isEdit: false,
      showForm: false,
      hasChangeCp: false,
      onboardComputers: [],
      siteModel: SITEMODEL,
      uavs: [],
      CPList: [],
      projectList: [],
      models: [
        {
          value: 'SkyCab',
          label: 'SkyCab',
        },
      ],
      states: [
        {
          value: 1,
          label: '库存',
        },
        {
          value: 2,
          label: '使用中',
        },
        {
          value: 3,
          label: '报废',
        },
      ],
      systems: [
        {
          value: 'windows',
          label: 'windows',
        },
        {
          value: 'android',
          label: 'android',
        },
        {
          value: 'linux',
          label: 'linux',
        },
      ],
      onboardComputer: {
        SN: '',
      },
      onboardComputerDetails: {
        CPID: '',
        PID: '',
        ECID: '',
        brand: '',
        model: '',
        mac: '',
        system: '',
        ipAddr: '',
        state: '',
        context: '',
        UAVID: '',
      },
      cache: '', //缓存所需要编辑的数据源
      rules: {
        CPID: [{ required: true, message: '请选择企业', trigger: 'change' }],
        ECID: [
          { required: true, message: '请输入设备机身（SN）号', trigger: 'blur' },
          { min: 10, max: 30, message: '长度在 10 到 30 个字符', trigger: 'blur' },
        ],
        brand: [
          { required: true, message: '请输入制造商', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' },
        ],
        model: [{ required: true, message: '请选择设备型号', trigger: 'change' }],
        mac: [{ required: true, validator: validateMAC, trigger: 'blur' }],
        ipAddr: [{ required: true, validator: validateIPAddr, trigger: 'blur' }],
        state: [{ required: true, message: '请选择状态', trigger: 'change' }],
        system: [{ required: true, message: '请选择操作系统', trigger: 'change' }],
        UAVID: [{ required: true, message: '请选择无人机', trigger: 'change' }],
      },
    };
  },
  created() {
    this.handleOpen();
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
    this.getDroneList();
  },
  mounted() {
    this.getSourceSize(200).then((size) => {
      console.log(size);
      this.size = size;
      this.getOnboardComputers(this.page, this.size, this.onboardComputer.SN);
    });
  },
  methods: {
    handleOpen() {
      let u = getUserInformation();
      let CPList = getUserCorporationsInformation();
      if (u && u.userInformation && u.userInformation.userId) {
        this.CPList = CPList;
        if (this.CPList.length > 1) {
          this.hasChangeCp = true;
        } else {
          const CPIDS = this.CPList.map(({ cpId }) => cpId);
          this.onboardComputerDetails.CPID = CPIDS[0];
          this.getProjectBycpIds(CPIDS);
        }
      }
    },

    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    //打开新增dialog
    handleClickAdd() {
      this.showForm = true;
    },

    //关闭dialog
    handleClose(formName) {
      this.resetForm(formName);
      for (let key in this.onboardComputerDetails) this.onboardComputerDetails[key] = '';
      this.isEdit = false;
    },

    //翻页
    handleCurrentChange(page) {
      this.page = page;
      this.getOnboardComputers(this.page, this.size, this.onboardComputer.SN);
    },

    //切换企业时项目列表
    handleChangeCorporation(cpId) {
      this.projectList = [];
      this.onboardComputerDetails.PID = '';
      cpId && this.getProjectBycpIds([cpId]);
    },

    //编辑边缘计算机
    handleEditOnboardComputed(data) {
      this.cache = JSON.parse(JSON.stringify(data));
      for (let key in this.onboardComputerDetails) this.onboardComputerDetails[key] = data[key];
      this.isEdit = true;
      this.showForm = true;
    },

    //提交边缘计算机信息
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = this.isEdit ? await editOnboardComputer(this.onboardComputerDetails) : await writeOnboardComputer(this.onboardComputerDetails);
          if (!statusInclude(code)) return this.$message.error(`操作失败：${reason}`);
          this.$message.success('操作成功');
          this.showForm = false;
          this.getOnboardComputers(this.page, this.size, this.onboardComputer.SN);
        }
      });
    },

    //关闭表格清理表格残留数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.showForm = false;
    },

    //请求企业下项目信息
    async getProjectBycpIds(cpIds) {
      const { code, data, reason } = await getProjectBycpIds(cpIds);
      this.projectList = data;
      return data;
    },

    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },

    //请求机载电脑列表
    async getOnboardComputers(page, size, ECID) {
      const { code, data, reason } = await getOnboardComputers(page, size, ECID);
      if (!statusInclude(code) || !Boolean(data)) {
        this.total = 0;
        this.onboardComputers = [];
        return this.$message.error(`请求失败：${reason}`);
      }
      this.onboardComputers = data.list;
      this.total = data.total ? data.total : this.total;
    },

    //请求无人机列表
    async getDroneList(page = 1, size = 500) {
      const { code, data, reason } = await getDroneList(page, size);
      if (!statusInclude(code)) return this.$message.error(`请求无人机数据失败：${reason}`);
      this.uavs = data.list.map(({ UAVID }) => UAVID);
    },

    async handleDeltetOnboardComputer(ECID) {
      this.$confirm('删除此边缘计算及设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code, reason } = await deleteOnboardComputer(ECID);
          if (!statusInclude(code)) return this.$message.error(`删除失败：${reason}`);
          this.$message({ type: 'success', message: '删除成功!' });
          this.getOnboardComputers(this.page, this.size, this.onboardComputer.SN);
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style lang="scss" scoped>
.onboard-computer-wrapper {
  .obboard-computer-info {
    &.el-card {
      ::v-deep .el-card__body {
        height: calc(100vh - #{$offset});
        position: relative;
        .el-dialog__wrapper {
          .el-dialog {
            .el-form {
              .el-form-item {
                .el-form-item__content {
                  .el-select {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
